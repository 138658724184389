import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const hamburgerIcon = (
    <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"></path>
    </svg>
  );

  const closeIcon = (
    <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
    </svg>
  );

  return (
    <>
      {/* Верхняя полоса с контактной информацией */}
      <div className="hidden md:flex bg-gray-200 text-gray-600 text-sm p-2 xl:px-0 px-5">
        <div className="container max-w-6xl mx-auto flex justify-between">
          <div className='flex'>
            <div>офис: г. Рязань, ул. Грибоедова, 65</div>
            <div className='ml-10'>часы работы: Пн-Пт 09:00 - 18:00</div>
          </div>
          <div>тел: +7 (4912) 28-13-15</div>
        </div>
      </div>

      <nav className="bg-white shadow-lg">
        <div className="container max-w-6xl mx-auto px-4 xl:px-0 py-3 justify-between flex items-center">
          <Link to="/">
            <img className='w-32' src="logo.svg" alt="Logo"/>
          </Link>


          {/* Скрыть на маленьких экранах, показать на больших */}
          <div className="hidden md:flex">
            <a href="https://yandex.ru/maps/org/orbita/212190908047/reviews/" rel='noreferrer' target='_blank' className="text-black text-md px-4 py-2 hover:text-gray-500 transition-colors duration-200 font-medium">
              Отзывы
            </a>
            <Link to="/contacts" className="text-black text-md px-4 py-2 hover:text-gray-500 transition-colors duration-200 font-medium">
              Контакты
            </Link>
            <a href="https://vk.com/lagerorbita" target='_blank' rel='noreferrer' className="text-black text-md px-4 py-2 hover:text-gray-500 transition-colors duration-200 font-medium">
              Группа ВК
            </a>
            <a href="https://dynamo-ryazan.ru/" target='_blank' rel='noreferrer' className="text-black text-md px-4 py-2 hover:text-gray-500 transition-colors duration-200 font-medium">
              Динамо
            </a>
          </div>

          <Link to="/purchase" className="text-white bg-blue text-md px-4 py-1 border-2 border-blue hover:bg-white hover:border-black hover:text-black transition-colors duration-200 font-medium">
              Купить путевку
            </Link>

          {/* Кнопка гамбургера */}
          <button onClick={() => setIsMenuOpen(!isMenuOpen)} 
                  className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200">
            <span className="sr-only">Open main menu</span>
            {isMenuOpen ? closeIcon : hamburgerIcon}
          </button>


        </div>

        {/* Мобильное меню, открывается вниз */}
        {isMenuOpen && (
          <div className="md:hidden transform">
            <Link to="/" className="text-black text-md block px-4 py-2 font-medium">
              Главная
            </Link>
            <Link to="/contacts" className="text-black text-md block px-4 py-2 font-medium">
              Контакты
            </Link>
            <a href="https://yandex.ru/maps/org/orbita/212190908047/reviews/" rel='noreferrer' target='_blank' className="text-black text-md block px-4 py-2 hover:bg-gray-200 transition-colors duration-200 font-medium">
              Отзывы
            </a>
            <a href="https://vk.com/lagerorbita" target='_blank' rel='noreferrer' className="text-black text-md block px-4 py-2 hover:bg-gray-200 transition-colors duration-200 font-medium">
              Группа ВК
            </a>
            <a href="https://dynamo-ryazan.ru/" target='_blank' rel='noreferrer' className="text-black text-md block px-4 py-2 hover:bg-gray-200 transition-colors duration-200 font-medium">
              Динамо
            </a>
          </div>
        )}
      </nav>
    </>
  );
};

export default Header;
