import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Link as ScrollLink, Element } from 'react-scroll';
import Footer from './Footer';
import Header from './Header';
import axios from 'axios';

const HomePage = () => {
  const [camps, setCamps] = useState([]);
  const [error, setError] = useState(null);
  const apiBaseURL = "https://orbita62.ru/api";

  useEffect(() => {
    const fetchCamps = async () => {
      try {
        const response = await axios.get(`${apiBaseURL}/api/camps`);
        setCamps(response.data);
        setError(null); 
        if (response.data.length === 0) {
          setError('Не удалось загрузить данные о сменах');
        }
      } catch (error) {
        console.error('Ошибка при загрузке данных смен', error);
        setError('Не удалось загрузить данные о сменах');
      }
    };

    fetchCamps();
  }, []);

  return (
    <div>
      <Header />
      {/* Основной контент */}
      <div className="bg-blue custom-bg w-full">
        <div className="container max-w-6xl mx-auto py-20">
          {/* Слоган */}
          <div className="md:mt-5">
            <p className="text-white md:text-9xl text-5xl font-lemon text-center leading-slogan">
              Тот самый<br />космический движ!
            </p>
          </div>
          {/* Кнопки */}
          <div className="md:mt-24 mt-8 flex flex-col sm:flex-row justify-center">
            <Link
              to="/purchase"
              className="text-center border border-white text-white bg-transparent hover:bg-white hover:text-black transition-colors duration-200 lg:mr-10 lg:mx-0 mx-10 my-2 text-md sm:text-lg font-medium px-10 sm:px-20 py-2 sm:py-4"
            >
              Купить путевку
            </Link>

            <ScrollLink
              to="about"
              ignoreCancelEvents={true}
              smooth={true}
              duration={500}
              className="border border-white text-black bg-white hover:bg-transparent hover:cursor-pointer hover:text-white text-center transition-colors duration-200 md:mx-0 mx-10 my-2 text-md sm:text-lg font-medium px-10 sm:px-20 py-2 sm:py-4"
            >
              Подробнее о лагере
            </ScrollLink>
          </div>
        </div>


        
        <div className="pt-20 rounded-t-3xl bg-white">
          <div className="container max-w-6xl mx-auto lg:px-0 px-5 mb-24">
            <h1 className="text-4xl lg:text-5xl font-bold mb-14">
                АКТУАЛЬНЫЕ<br />
                <span className="color-primary">СМЕНЫ</span>
            </h1>
            {camps.map(camp => (
                <div key={camp.camp_id} className="mb-5 lg:grid lg:grid-cols-4 bg-gray-50 p-5">
                    <p className="text-xl lg:font-medium font-bold">{camp.camp_title}</p>
                    <p className="lg:text-center text-xl font-medium">{camp.camp_start_date} – {camp.camp_end_date}</p>
                    <p className="lg:text-center text-xl font-medium">Осталось мест: {camp.camp_available_seats}</p>
                    <p className="lg:text-end text-xl font-medium">Стоимость: {camp.camp_cost}₽</p>
                </div>
            ))}  
            {error && 
            
            <div className="mb-5 bg-gray-50 p-5">
              <p className="text-xl font-medium">Информация о сменах недоступна</p>
            </div> 
            
            }             
          </div>




          {/* О орбите */}
          <Element name="about" className="w-full"></Element>
          <div className="container max-w-6xl mx-auto flex">
            <div className="flex-1 hidden lg:block mt-8">
              <img src="photo.png" alt="Изображение" />
            </div>
            <div className="flex-1 lg:mt-5 lg:mx-0 mx-5">
              <h2 className="text-4xl lg:text-5xl font-bold">
                НЕМНОГО О<br />
                <span className="color-primary">ОРБИТЕ</span>
              </h2>
              <p className="mt-10">
              Лагерь «Орбита» расположен в экологически-чистом районе всего в 60 км от Рязани. Ежегодно лагерь модернизируется и совершенствуется, чтобы отдых детей был максимально продвинутым и комфортным. Уютные корпуса, просторный спортивный зал, яркая эстрада, прекрасная столовая и множество арт-площадок.
              </p>
              <p className='my-5'>Общая программа смен, продолжительностью 14-15 дней, каждая из которых имеет определенную тематику. Комплекс мероприятий тематики смены в легкой, игровой форме погрузит ребенка в атмосферу творчества и позволит не только раскрыть свои таланты, но и научиться множеству новых вещей.</p>
              <p className="font-medium">
              Подключайся в <span className='color-primary'>#ОрбитныйДвиж</span>!
              </p>
              <div className="mt-14 flex">
                <img src="vtb.svg" alt="ВТБ" />
                <img className="ml-5" src="dynamo.png" alt="Динамо" />
              </div>
            </div>
          </div>
          {/* Карта */}
          <div className="mx-auto mt-24">
            <iframe
              title="Карта"
              src="https://yandex.ru/map-widget/v1/?um=constructor%3Affafc6a42eef6f8875658957da50ed13549f1590eb7ea1c50c3cdda96cf09f74&amp;source=constructor"
              width="100%"
              height="600"
              frameBorder="0"
            ></iframe>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default HomePage;
